import React, { FC, useEffect, useState, useCallback } from "react";
import { Breadcrumb, Input, Flex, Badge, Avatar, Dropdown, MenuProps } from "antd";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as BellIcon } from '../../assets/icons/Bell.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/Logout.svg';
import { SearchProps } from "antd/es/input";
import { sanitizeBreadcrumb } from "../../helpers/breadcrumbsHelper";
import { useAuth } from "../app/AuthProvider";

const Header: FC = () => {
  const { Search } = Input;
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isDot, setIsDot] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<any>([{ title: '' }]);

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

  const getNavTitle = (title: string) => {
    if (!title) return 'Main';
    if (title === "homeworksetter") return "Homework Setter";
    return sanitizeBreadcrumb(title[0].toUpperCase() + title.substring(1));
  };

  const goBackThreeSteps = useCallback(() => {
    navigate(-3);
  }, [navigate]);

  useEffect(() => {
    const pathArray = location.pathname.substring(1).split('/');
    let pathSoFar = '';
    const searchParams = location.search;

    const newBreadcrumbs = pathArray.map((item, index) => {
      pathSoFar += `/${item}`;
      const isLast = index === pathArray.length - 1;
      const fullPath = `${pathSoFar}${searchParams}`;
      return {
        title: isLast
          ? <span>{getNavTitle(item)}</span>
          : <NavLink to={fullPath}>{getNavTitle(item)}</NavLink>
      };
    });

    if (newBreadcrumbs.length > 3) {
      const ellipsis = {
        title: <span style={{ color: 'rgba(0, 0, 0, 0.45)', cursor: 'pointer' }} onClick={goBackThreeSteps}>{'>> ... '} </span>
      };
      const first = newBreadcrumbs[newBreadcrumbs.length - 3];
      const second = newBreadcrumbs[newBreadcrumbs.length - 2];
      const last = newBreadcrumbs[newBreadcrumbs.length - 1];
      setBreadcrumbs([ellipsis, first, second, last]);
    } else {
      setBreadcrumbs(newBreadcrumbs);
    }
  }, [location, goBackThreeSteps]);

  const items: MenuProps['items'] = [
    {
      key: 'logout',
      label: (
        <a onClick={logout} style={{ display: 'flex', alignItems: 'center' }}>
          <LogoutIcon style={{ marginRight: 8 }} />
          Log Out
        </a>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Breadcrumb separator={">>"} items={breadcrumbs} className={"breadcrumbs"} />
      <Flex className={"interactive"}>
        <Search placeholder={"Search for admin"} allowClear onSearch={onSearch} size={"large"} className={"search"} />
        <Badge dot={isDot} color={"#2B78E4"} className={"notification"}>
          <BellIcon />
        </Badge>
        <Dropdown trigger={['click']} menu={{ items }} placement="bottomRight">
          <Avatar style={{cursor: 'pointer'}} size={48} className={"avatar"}>{'U'}</Avatar>
        </Dropdown>
      </Flex>
    </React.Fragment>
  );
}

export default Header;
