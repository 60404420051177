import { FC, lazy, createElement } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import DefaultLayout from "../components/layout";
import RequirePermission from "./RequirePermission";
import LoginRoute from "./LoginRoute";
import CookiesTrackingPolicy from "./cookiesTrackingPolicy/CookiesTrackingPolicy"
const Home = lazy<FC>(() => import("./home"));
const SchoolsPage = lazy<FC>(() => import("./admin/schools/SchoolsPage"));
const UsersPage = lazy<FC>(() => import("./admin/users/UsersPage"));
const SettingsPage = lazy<FC>(() => import("./settings/SettingsPage"));
const SubjectsPage = lazy<FC>(() => import("./subjects/SubjectsPage"));
const UnitPage = lazy<FC>(() => import("./units/UnitsPage"));
const LoginPage = lazy<FC>(() => import("./sign-in/SignIn"));
const AuthPage = lazy<FC>(() => import("./auth/Auth"));
const ChapterPage = lazy<FC>(() => import("./chapters/ChaptersPage"));
const TopicPage = lazy<FC>(() => import("./topics/TopicPage"));
const TopicsAllPage = lazy<FC>(() => import("./topics/TopicsAllPage"));
const UserSchoolPage = lazy<FC>(()=> import('./school/users/UsersPage'))
const ClassesPage = lazy<FC>(()=>import('./school/classes/ClassesPage'))
const ClassPage = lazy<FC>(()=>import('./school/classes/class/ClassPage'))
const SchoolSubjectPage = lazy<FC>(()=>import('./school/subjects/SchoolSubjectPage'))
const HMSetterPage = lazy<FC>(()=>import('./teacher/HMSetter/HMSetterPage'))
const StudentHomeWorkPage = lazy<FC>(()=> import('./student/homeWork/HomeWorkPage'))
const StudentHomeWorkDetailsPage = lazy<FC>(()=> import('./student/homeWorkDetails/HomeWorkDetailsPage'))
const StudentAllHomeWorksPage = lazy<FC>(()=> import('./student/allHomeWork/AllHomeworkPage'))
const TeacherHomeWorkPage = lazy<FC>(()=> import('./teacher/homeWork/HomeWorkPage'))
const ClassesHomeWorkPage = lazy<FC>(()=> import('./teacher/classesHomeWork/ClassesHomeWorkPage'))
const StudentHomeWorksPage= lazy<FC>(()=> import('./teacher/studentHomeWork/StudentHomeWorkPage'))
const QuizDetailsPage = lazy<FC>(()=> import('./teacher/quizDetails/QuizDetailsPage'))
const PrivacyPolicyPage = lazy<FC>(()=> import('./privacyPolicy/PrivacyPolicy'))
const CookiesTrackingPolicyPage = lazy<FC>(()=> import('./cookiesTrackingPolicy/CookiesTrackingPolicy'))
const TermsAndConditionsPage= lazy<FC>(()=> import('./termsAndConditions/TermsAndConditions'))
export const guestRoutes: RouteObject[] = [
  {
    element: <DefaultLayout />,
    children: [
      {
        element: <RequirePermission>{createElement(Home)}</RequirePermission>,
        path: "/",
        index: true,
      },
      {
        element: <LoginRoute>{createElement(LoginPage)}</LoginRoute>,
        path: "sign-in",
        index: true,
      },
      {
        element: <LoginRoute>{createElement(PrivacyPolicyPage)}</LoginRoute>,
        path: "privacy-policy",
        index: true,
      },
      {
        element: <LoginRoute>{createElement(TermsAndConditionsPage)}</LoginRoute>,
        path: "terms-and-conditions",
        index: true,
      },
      {
        element: <LoginRoute>{createElement(CookiesTrackingPolicyPage)}</LoginRoute>,
        path: "cookies-tracking-policy",
        index: true,
      },
      {
        element: <LoginRoute>{createElement(AuthPage)}</LoginRoute>,
        path: "auth",
        index: true,
      },
      {
        element: <Outlet />,
        path: "*",
      },
    ]
  }
];

export const adminRoutes: RouteObject[] = [
  {
    element: <DefaultLayout />,
    children: [
      {
        element: <RequirePermission>{createElement(Home)}</RequirePermission>,
        path: "/",
        index: true,
      },
      {
        path: "subjects",
        children: [
          {
            element: <RequirePermission>{createElement(SubjectsPage)}</RequirePermission>,
            index: true,
          },
          {
            path: ":subjectId",
            children: [
              {
                element: <RequirePermission>{createElement(UnitPage)}</RequirePermission>,
                index: true,
              },
              {
                path: ":unitId",
                children: [
                  {
                    element: <RequirePermission>{createElement(ChapterPage)}</RequirePermission>,
                    index: true
                  },
                  {
                    path: ":chapterId",
                    children: [
                      {
                        element: <RequirePermission>{createElement(TopicsAllPage)}</RequirePermission>,
                        index: true
                      },
                      {
                        path: ":topicId",
                        children: [
                          {
                            element: <RequirePermission>{createElement(TopicPage)}</RequirePermission>,
                            index: true
                          }
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <RequirePermission>{createElement(SchoolsPage)}</RequirePermission>,
        path: "schools",
        index: true,
      },
      // {
      //   element: <RequirePermission>{createElement(UsersPage)}</RequirePermission>,
      //   path: "users",
      //   index: true,
      // },
      {
        element: <RequirePermission>{createElement(SettingsPage)}</RequirePermission>,
        path: "settings",
        index: true,
      },
    ],
  },
];

export const schoolRoutes: RouteObject[] = [
  {
    element: <DefaultLayout />,
    children: [
      {
        element: <RequirePermission>{createElement(Home)}</RequirePermission>,
        path: "/",
        index: true,
      },
      {
        element: <RequirePermission>{createElement(SettingsPage)}</RequirePermission>,
        path: "settings",
        index: true,
      },
      // {
      //   element: <RequirePermission>{createElement(UserSchoolPage)}</RequirePermission>,
      //   path: "users",
      //   index: true,
      // },
      {
        element: <RequirePermission>{createElement(ClassesPage)}</RequirePermission>,
        path: "classes",
        index: true,
      },
      {
        element: <RequirePermission>{createElement(ClassPage)}</RequirePermission>,
        path: "classes/:classId",
        index: false,
      },

      {
        path: "subjects",
        children: [
          {
            element: <RequirePermission>{createElement(SchoolSubjectPage)}</RequirePermission>,
            index: true,
          },
          {
            path: ":subjectId",
            children: [
              {
                element: <RequirePermission>{createElement(UnitPage)}</RequirePermission>,
                index: true,
              },
              {
                path: ":unitId",
                children: [
                  {
                    element: <RequirePermission>{createElement(ChapterPage)}</RequirePermission>,
                    index: true
                  },
                  {
                    path: ":chapterId",
                    children: [
                      {
                        element: <RequirePermission>{createElement(TopicsAllPage)}</RequirePermission>,
                        index: true
                      },
                      {
                        path: ":topicId",
                        children: [
                          {
                            element: <RequirePermission>{createElement(TopicPage)}</RequirePermission>,
                            index: true
                          }
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        element: <Outlet />,
        path: "*",
      },
    ],
  }
];
export const studentRoutes: RouteObject[] = [
  {
    element: <DefaultLayout />,
    children: [
      {
        element: <RequirePermission>{createElement(Home)}</RequirePermission>,
        path: "/",
        index: true,
      },
      {
        element: <RequirePermission>{createElement(SettingsPage)}</RequirePermission>,
        path: "settings",
        index: true,
      },
      {
        element: <RequirePermission>{createElement(StudentHomeWorkPage)}</RequirePermission>,
        path: "homework",
        index: true,
      },
      {
        element: <RequirePermission>{createElement(StudentHomeWorkDetailsPage)}</RequirePermission>,
        path: "homework/:id",
      },
      {
        element: <RequirePermission>{createElement(StudentAllHomeWorksPage)}</RequirePermission>,
        path: "allhomework/:id",
      },
      {
        path: "subjects",
        children: [
          {
            element: <RequirePermission>{createElement(SubjectsPage)}</RequirePermission>,
            index: true,
          },
          {
            path: ":subjectId",
            children: [
              {
                element: <RequirePermission>{createElement(UnitPage)}</RequirePermission>,
                index: true,
              },
              {
                path: ":unitId",
                children: [
                  {
                    element: <RequirePermission>{createElement(ChapterPage)}</RequirePermission>,
                    index: true,
                  },
                  {
                    path: ":chapterId",
                    children: [
                      {
                        element: <RequirePermission>{createElement(TopicsAllPage)}</RequirePermission>,
                        index: true,
                      },
                      {
                        path: ":topicId",
                        children: [
                          {
                            element: <RequirePermission>{createElement(TopicPage)}</RequirePermission>,
                            index: true,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <Outlet />,
        path: "*",
      },
    ],
  },
]
export const teacherRoutes: RouteObject[] = [
  {
    element: <DefaultLayout />,
    children: [
      {
        element: <RequirePermission>{createElement(Home)}</RequirePermission>,
        path: "/",
        index: true,
      },
      {
        element: <RequirePermission>{createElement(SettingsPage)}</RequirePermission>,
        path: "settings",
        index: true,
      },
      {
        element: <RequirePermission>{createElement(TeacherHomeWorkPage)}</RequirePermission>,
        path: "homework",
        index: true,
      },
      {
        element: <RequirePermission>{createElement(HMSetterPage)}</RequirePermission>,
        path: "homeworksetter",
        index: true,
      },
      {
        element: <RequirePermission>{createElement(ClassesHomeWorkPage)}</RequirePermission>,
        path: "homework/:id",
        index: true,
      },
      {
        element: <RequirePermission>{createElement( StudentHomeWorksPage)}</RequirePermission>,
        path: "homework/:id/:classId",
        index: true,
      },
      {
        element: <RequirePermission>{createElement(QuizDetailsPage)}</RequirePermission>,
        path: "homework/:id/:classId/:studentId",
        index: true,
      },
      {
        path: "subjects",
        children: [
          {
            element: <RequirePermission>{createElement(SubjectsPage)}</RequirePermission>,
            index: true,
          },
          {
            path: ":subjectId",
            children: [
              {
                element: <RequirePermission>{createElement(UnitPage)}</RequirePermission>,
                index: true,
              },
              {
                path: ":unitId",
                children: [
                  {
                    element: <RequirePermission>{createElement(ChapterPage)}</RequirePermission>,
                    index: true
                  },
                  {
                    path: ":chapterId",
                    children: [
                      {
                        element: <RequirePermission>{createElement(TopicsAllPage)}</RequirePermission>,
                        index: true
                      },
                      {
                        path: ":topicId",
                        children: [
                          {
                            element: <RequirePermission>{createElement(TopicPage)}</RequirePermission>,
                            index: true
                          }
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        element: <Outlet />,
        path: "*",
      },
    ],
  }
]
