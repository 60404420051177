import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Chapter",
      "Class",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentDataTextBlock",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "CookiesTrackingPolicy",
      "Home",
      "Homework",
      "Layout",
      "Level",
      "PrivacyPolicy",
      "Quiz",
      "QuizResponse",
      "QuizType",
      "ReactIconsIconlibrary",
      "School",
      "Subject",
      "TermsAndCondition",
      "Topic",
      "Unit",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Chapter",
      "Class",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentDataTextBlock",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "CookiesTrackingPolicy",
      "Home",
      "Homework",
      "Layout",
      "Level",
      "PrivacyPolicy",
      "Quiz",
      "QuizResponse",
      "QuizType",
      "ReactIconsIconlibrary",
      "School",
      "Subject",
      "TermsAndCondition",
      "Topic",
      "Unit",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const MeFragmentDoc = gql`
    fragment Me on UsersPermissionsUserEntity {
  attributes {
    username
    email
    school {
      data {
        id
      }
    }
    name
    role {
      data {
        attributes {
          name
        }
      }
    }
  }
  id
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const AddStudentsToClassDocument = gql`
    mutation AddStudentsToClass($input: AddStudentsToClassInput!) {
  addStudentsToClass(input: $input) {
    username
  }
}
    `;
export type AddStudentsToClassMutationFn = Apollo.MutationFunction<AddStudentsToClassMutation, AddStudentsToClassMutationVariables>;
export function useAddStudentsToClassMutation(baseOptions?: Apollo.MutationHookOptions<AddStudentsToClassMutation, AddStudentsToClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStudentsToClassMutation, AddStudentsToClassMutationVariables>(AddStudentsToClassDocument, options);
      }
export type AddStudentsToClassMutationHookResult = ReturnType<typeof useAddStudentsToClassMutation>;
export type AddStudentsToClassMutationResult = Apollo.MutationResult<AddStudentsToClassMutation>;
export const RemoveStudentsFromClassDocument = gql`
    mutation RemoveStudentsFromClass($input: RemoveStudentsFromClassInput!) {
  removeStudentsFromClass(input: $input) {
    username
  }
}
    `;
export type RemoveStudentsFromClassMutationFn = Apollo.MutationFunction<RemoveStudentsFromClassMutation, RemoveStudentsFromClassMutationVariables>;
export function useRemoveStudentsFromClassMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStudentsFromClassMutation, RemoveStudentsFromClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStudentsFromClassMutation, RemoveStudentsFromClassMutationVariables>(RemoveStudentsFromClassDocument, options);
      }
export type RemoveStudentsFromClassMutationHookResult = ReturnType<typeof useRemoveStudentsFromClassMutation>;
export type RemoveStudentsFromClassMutationResult = Apollo.MutationResult<RemoveStudentsFromClassMutation>;
export const AssignSubjectDocument = gql`
    mutation AssignSubject($input: AssignSubjectInput!) {
  assignSubject(input: $input) {
    createdAt
  }
}
    `;
export type AssignSubjectMutationFn = Apollo.MutationFunction<AssignSubjectMutation, AssignSubjectMutationVariables>;
export function useAssignSubjectMutation(baseOptions?: Apollo.MutationHookOptions<AssignSubjectMutation, AssignSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignSubjectMutation, AssignSubjectMutationVariables>(AssignSubjectDocument, options);
      }
export type AssignSubjectMutationHookResult = ReturnType<typeof useAssignSubjectMutation>;
export type AssignSubjectMutationResult = Apollo.MutationResult<AssignSubjectMutation>;
export const CheckQuizDocument = gql`
    mutation CheckQuiz($input: CheckQuizInput!) {
  checkQuiz(input: $input) {
    data
  }
}
    `;
export type CheckQuizMutationFn = Apollo.MutationFunction<CheckQuizMutation, CheckQuizMutationVariables>;
export function useCheckQuizMutation(baseOptions?: Apollo.MutationHookOptions<CheckQuizMutation, CheckQuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckQuizMutation, CheckQuizMutationVariables>(CheckQuizDocument, options);
      }
export type CheckQuizMutationHookResult = ReturnType<typeof useCheckQuizMutation>;
export type CheckQuizMutationResult = Apollo.MutationResult<CheckQuizMutation>;
export const CreateChapterDocument = gql`
    mutation CreateChapter($input: CreateChapterInput!) {
  createChapter(input: $input) {
    publishedAt
    name
    description
  }
}
    `;
export type CreateChapterMutationFn = Apollo.MutationFunction<CreateChapterMutation, CreateChapterMutationVariables>;
export function useCreateChapterMutation(baseOptions?: Apollo.MutationHookOptions<CreateChapterMutation, CreateChapterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChapterMutation, CreateChapterMutationVariables>(CreateChapterDocument, options);
      }
export type CreateChapterMutationHookResult = ReturnType<typeof useCreateChapterMutation>;
export type CreateChapterMutationResult = Apollo.MutationResult<CreateChapterMutation>;
export const CreateHomeWorkDocument = gql`
    mutation CreateHomeWork($input: CreateHomeWorkInput!) {
  createHomework(input: $input) {
    name
  }
}
    `;
export type CreateHomeWorkMutationFn = Apollo.MutationFunction<CreateHomeWorkMutation, CreateHomeWorkMutationVariables>;
export function useCreateHomeWorkMutation(baseOptions?: Apollo.MutationHookOptions<CreateHomeWorkMutation, CreateHomeWorkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHomeWorkMutation, CreateHomeWorkMutationVariables>(CreateHomeWorkDocument, options);
      }
export type CreateHomeWorkMutationHookResult = ReturnType<typeof useCreateHomeWorkMutation>;
export type CreateHomeWorkMutationResult = Apollo.MutationResult<CreateHomeWorkMutation>;
export const CreateSubjectDocument = gql`
    mutation CreateSubject($input: CreatSubjectInput!) {
  createSubject(input: $input) {
    name
    description
    publishedAt
    level {
      data {
        attributes {
          name
        }
      }
    }
  }
}
    `;
export type CreateSubjectMutationFn = Apollo.MutationFunction<CreateSubjectMutation, CreateSubjectMutationVariables>;
export function useCreateSubjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubjectMutation, CreateSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubjectMutation, CreateSubjectMutationVariables>(CreateSubjectDocument, options);
      }
export type CreateSubjectMutationHookResult = ReturnType<typeof useCreateSubjectMutation>;
export type CreateSubjectMutationResult = Apollo.MutationResult<CreateSubjectMutation>;
export const CreateTopicDocument = gql`
    mutation CreateTopic($input: CreateTopicInput!) {
  createTopic(input: $input) {
    publishedAt
    name
  }
}
    `;
export type CreateTopicMutationFn = Apollo.MutationFunction<CreateTopicMutation, CreateTopicMutationVariables>;
export function useCreateTopicMutation(baseOptions?: Apollo.MutationHookOptions<CreateTopicMutation, CreateTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTopicMutation, CreateTopicMutationVariables>(CreateTopicDocument, options);
      }
export type CreateTopicMutationHookResult = ReturnType<typeof useCreateTopicMutation>;
export type CreateTopicMutationResult = Apollo.MutationResult<CreateTopicMutation>;
export const CreateUnitDocument = gql`
    mutation CreateUnit($input: CreateUnitInput!) {
  createUnit(input: $input) {
    publishedAt
    name
    description
  }
}
    `;
export type CreateUnitMutationFn = Apollo.MutationFunction<CreateUnitMutation, CreateUnitMutationVariables>;
export function useCreateUnitMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnitMutation, CreateUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUnitMutation, CreateUnitMutationVariables>(CreateUnitDocument, options);
      }
export type CreateUnitMutationHookResult = ReturnType<typeof useCreateUnitMutation>;
export type CreateUnitMutationResult = Apollo.MutationResult<CreateUnitMutation>;
export const DeleteChapterDocument = gql`
    mutation DeleteChapter($id: ID!) {
  deleteChapter(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteChapterMutationFn = Apollo.MutationFunction<DeleteChapterMutation, DeleteChapterMutationVariables>;
export function useDeleteChapterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChapterMutation, DeleteChapterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChapterMutation, DeleteChapterMutationVariables>(DeleteChapterDocument, options);
      }
export type DeleteChapterMutationHookResult = ReturnType<typeof useDeleteChapterMutation>;
export type DeleteChapterMutationResult = Apollo.MutationResult<DeleteChapterMutation>;
export const DeleteSchoolDocument = gql`
    mutation DeleteSchool($id: ID!) {
  deleteSchool(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteSchoolMutationFn = Apollo.MutationFunction<DeleteSchoolMutation, DeleteSchoolMutationVariables>;
export function useDeleteSchoolMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchoolMutation, DeleteSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchoolMutation, DeleteSchoolMutationVariables>(DeleteSchoolDocument, options);
      }
export type DeleteSchoolMutationHookResult = ReturnType<typeof useDeleteSchoolMutation>;
export type DeleteSchoolMutationResult = Apollo.MutationResult<DeleteSchoolMutation>;
export const DeleteSubjectDocument = gql`
    mutation DeleteSubject($id: ID!) {
  deleteSubject(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteSubjectMutationFn = Apollo.MutationFunction<DeleteSubjectMutation, DeleteSubjectMutationVariables>;
export function useDeleteSubjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubjectMutation, DeleteSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubjectMutation, DeleteSubjectMutationVariables>(DeleteSubjectDocument, options);
      }
export type DeleteSubjectMutationHookResult = ReturnType<typeof useDeleteSubjectMutation>;
export type DeleteSubjectMutationResult = Apollo.MutationResult<DeleteSubjectMutation>;
export const DeleteTopicDocument = gql`
    mutation DeleteTopic($id: ID!) {
  deleteTopic(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteTopicMutationFn = Apollo.MutationFunction<DeleteTopicMutation, DeleteTopicMutationVariables>;
export function useDeleteTopicMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTopicMutation, DeleteTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTopicMutation, DeleteTopicMutationVariables>(DeleteTopicDocument, options);
      }
export type DeleteTopicMutationHookResult = ReturnType<typeof useDeleteTopicMutation>;
export type DeleteTopicMutationResult = Apollo.MutationResult<DeleteTopicMutation>;
export const DeleteUnitDocument = gql`
    mutation DeleteUnit($id: ID!) {
  deleteUnit(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteUnitMutationFn = Apollo.MutationFunction<DeleteUnitMutation, DeleteUnitMutationVariables>;
export function useDeleteUnitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUnitMutation, DeleteUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUnitMutation, DeleteUnitMutationVariables>(DeleteUnitDocument, options);
      }
export type DeleteUnitMutationHookResult = ReturnType<typeof useDeleteUnitMutation>;
export type DeleteUnitMutationResult = Apollo.MutationResult<DeleteUnitMutation>;
export const DeleteUsersPermissionsUserDocument = gql`
    mutation DeleteUsersPermissionsUser($id: ID!) {
  deleteUsersPermissionsUser(id: $id) {
    data {
      attributes {
        createdAt
      }
    }
  }
}
    `;
export type DeleteUsersPermissionsUserMutationFn = Apollo.MutationFunction<DeleteUsersPermissionsUserMutation, DeleteUsersPermissionsUserMutationVariables>;
export function useDeleteUsersPermissionsUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersPermissionsUserMutation, DeleteUsersPermissionsUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsersPermissionsUserMutation, DeleteUsersPermissionsUserMutationVariables>(DeleteUsersPermissionsUserDocument, options);
      }
export type DeleteUsersPermissionsUserMutationHookResult = ReturnType<typeof useDeleteUsersPermissionsUserMutation>;
export type DeleteUsersPermissionsUserMutationResult = Apollo.MutationResult<DeleteUsersPermissionsUserMutation>;
export const InviteSchoolDocument = gql`
    mutation InviteSchool($input: InviteSchoolInput!) {
  inviteSchool(input: $input) {
    createdAt
  }
}
    `;
export type InviteSchoolMutationFn = Apollo.MutationFunction<InviteSchoolMutation, InviteSchoolMutationVariables>;
export function useInviteSchoolMutation(baseOptions?: Apollo.MutationHookOptions<InviteSchoolMutation, InviteSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteSchoolMutation, InviteSchoolMutationVariables>(InviteSchoolDocument, options);
      }
export type InviteSchoolMutationHookResult = ReturnType<typeof useInviteSchoolMutation>;
export type InviteSchoolMutationResult = Apollo.MutationResult<InviteSchoolMutation>;
export const InviteUserDocument = gql`
    mutation InviteUser($input: InviteUserInput!) {
  inviteUser(input: $input) {
    createdAt
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export const LoginDocument = gql`
    mutation Login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const PutQuizDocument = gql`
    mutation PutQuiz($input: PutQuizInput!) {
  putQuiz(input: $input) {
    publishedAt
  }
}
    `;
export type PutQuizMutationFn = Apollo.MutationFunction<PutQuizMutation, PutQuizMutationVariables>;
export function usePutQuizMutation(baseOptions?: Apollo.MutationHookOptions<PutQuizMutation, PutQuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PutQuizMutation, PutQuizMutationVariables>(PutQuizDocument, options);
      }
export type PutQuizMutationHookResult = ReturnType<typeof usePutQuizMutation>;
export type PutQuizMutationResult = Apollo.MutationResult<PutQuizMutation>;
export const RemoveSubjectDocument = gql`
    mutation RemoveSubject($input: RemoveSubjectInput!) {
  removeSubject(input: $input) {
    username
  }
}
    `;
export type RemoveSubjectMutationFn = Apollo.MutationFunction<RemoveSubjectMutation, RemoveSubjectMutationVariables>;
export function useRemoveSubjectMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSubjectMutation, RemoveSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSubjectMutation, RemoveSubjectMutationVariables>(RemoveSubjectDocument, options);
      }
export type RemoveSubjectMutationHookResult = ReturnType<typeof useRemoveSubjectMutation>;
export type RemoveSubjectMutationResult = Apollo.MutationResult<RemoveSubjectMutation>;
export const RemoveSubjectFromClassDocument = gql`
    mutation RemoveSubjectFromClass($input: RemoveSubjectFromClassInput!) {
  removeSubjectFromClass(input: $input) {
    username
  }
}
    `;
export type RemoveSubjectFromClassMutationFn = Apollo.MutationFunction<RemoveSubjectFromClassMutation, RemoveSubjectFromClassMutationVariables>;
export function useRemoveSubjectFromClassMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSubjectFromClassMutation, RemoveSubjectFromClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSubjectFromClassMutation, RemoveSubjectFromClassMutationVariables>(RemoveSubjectFromClassDocument, options);
      }
export type RemoveSubjectFromClassMutationHookResult = ReturnType<typeof useRemoveSubjectFromClassMutation>;
export type RemoveSubjectFromClassMutationResult = Apollo.MutationResult<RemoveSubjectFromClassMutation>;
export const SignUpUserDocument = gql`
    mutation signUpUser($input: SignUpUserInput!) {
  signUpUser(input: $input) {
    token
  }
}
    `;
export type SignUpUserMutationFn = Apollo.MutationFunction<SignUpUserMutation, SignUpUserMutationVariables>;
export function useSignUpUserMutation(baseOptions?: Apollo.MutationHookOptions<SignUpUserMutation, SignUpUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpUserMutation, SignUpUserMutationVariables>(SignUpUserDocument, options);
      }
export type SignUpUserMutationHookResult = ReturnType<typeof useSignUpUserMutation>;
export type SignUpUserMutationResult = Apollo.MutationResult<SignUpUserMutation>;
export const UpdateChapterDocument = gql`
    mutation UpdateChapter($input: UpdateChapterInput!) {
  updateChapter(input: $input) {
    name
    description
  }
}
    `;
export type UpdateChapterMutationFn = Apollo.MutationFunction<UpdateChapterMutation, UpdateChapterMutationVariables>;
export function useUpdateChapterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChapterMutation, UpdateChapterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChapterMutation, UpdateChapterMutationVariables>(UpdateChapterDocument, options);
      }
export type UpdateChapterMutationHookResult = ReturnType<typeof useUpdateChapterMutation>;
export type UpdateChapterMutationResult = Apollo.MutationResult<UpdateChapterMutation>;
export const UpdateSchoolDocument = gql`
    mutation UpdateSchool($id: ID!, $data: SchoolInput!) {
  updateSchool(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateSchoolMutationFn = Apollo.MutationFunction<UpdateSchoolMutation, UpdateSchoolMutationVariables>;
export function useUpdateSchoolMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchoolMutation, UpdateSchoolMutationVariables>(UpdateSchoolDocument, options);
      }
export type UpdateSchoolMutationHookResult = ReturnType<typeof useUpdateSchoolMutation>;
export type UpdateSchoolMutationResult = Apollo.MutationResult<UpdateSchoolMutation>;
export const UpdateSubjectDocument = gql`
    mutation UpdateSubject($input: UpdateSubjectInput!) {
  updateSubject(input: $input) {
    name
    description
  }
}
    `;
export type UpdateSubjectMutationFn = Apollo.MutationFunction<UpdateSubjectMutation, UpdateSubjectMutationVariables>;
export function useUpdateSubjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubjectMutation, UpdateSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubjectMutation, UpdateSubjectMutationVariables>(UpdateSubjectDocument, options);
      }
export type UpdateSubjectMutationHookResult = ReturnType<typeof useUpdateSubjectMutation>;
export type UpdateSubjectMutationResult = Apollo.MutationResult<UpdateSubjectMutation>;
export const UpdateTopicDocument = gql`
    mutation UpdateTopic($input: UpdateTopicInput!) {
  updateTopic(input: $input) {
    name
    videoUrl
    videoTitle
    revisionPoints
  }
}
    `;
export type UpdateTopicMutationFn = Apollo.MutationFunction<UpdateTopicMutation, UpdateTopicMutationVariables>;
export function useUpdateTopicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTopicMutation, UpdateTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTopicMutation, UpdateTopicMutationVariables>(UpdateTopicDocument, options);
      }
export type UpdateTopicMutationHookResult = ReturnType<typeof useUpdateTopicMutation>;
export type UpdateTopicMutationResult = Apollo.MutationResult<UpdateTopicMutation>;
export const UpdateUnitDocument = gql`
    mutation UpdateUnit($input: UpdateUnitInput!) {
  updateUnit(input: $input) {
    name
    description
  }
}
    `;
export type UpdateUnitMutationFn = Apollo.MutationFunction<UpdateUnitMutation, UpdateUnitMutationVariables>;
export function useUpdateUnitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnitMutation, UpdateUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnitMutation, UpdateUnitMutationVariables>(UpdateUnitDocument, options);
      }
export type UpdateUnitMutationHookResult = ReturnType<typeof useUpdateUnitMutation>;
export type UpdateUnitMutationResult = Apollo.MutationResult<UpdateUnitMutation>;
export const UpdateUsersPermissionsUserDocument = gql`
    mutation UpdateUsersPermissionsUser($id: ID!, $data: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id, data: $data) {
    data {
      attributes {
        name
      }
    }
  }
}
    `;
export type UpdateUsersPermissionsUserMutationFn = Apollo.MutationFunction<UpdateUsersPermissionsUserMutation, UpdateUsersPermissionsUserMutationVariables>;
export function useUpdateUsersPermissionsUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUsersPermissionsUserMutation, UpdateUsersPermissionsUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUsersPermissionsUserMutation, UpdateUsersPermissionsUserMutationVariables>(UpdateUsersPermissionsUserDocument, options);
      }
export type UpdateUsersPermissionsUserMutationHookResult = ReturnType<typeof useUpdateUsersPermissionsUserMutation>;
export type UpdateUsersPermissionsUserMutationResult = Apollo.MutationResult<UpdateUsersPermissionsUserMutation>;
export const VerifyTokenDocument = gql`
    mutation verifyToken($input: VerifyTokenInput!) {
  verifyToken(input: $input) {
    role
  }
}
    `;
export type VerifyTokenMutationFn = Apollo.MutationFunction<VerifyTokenMutation, VerifyTokenMutationVariables>;
export function useVerifyTokenMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTokenMutation, VerifyTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTokenMutation, VerifyTokenMutationVariables>(VerifyTokenDocument, options);
      }
export type VerifyTokenMutationHookResult = ReturnType<typeof useVerifyTokenMutation>;
export type VerifyTokenMutationResult = Apollo.MutationResult<VerifyTokenMutation>;
export const TermsAndConditionDocument = gql`
    query TermsAndCondition($pagination: PaginationArg!) {
  termsAndCondition {
    data {
      attributes {
        block(pagination: $pagination) {
          description
          title
        }
      }
    }
  }
}
    `;
export function useTermsAndConditionQuery(baseOptions: Apollo.QueryHookOptions<TermsAndConditionQuery, TermsAndConditionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TermsAndConditionQuery, TermsAndConditionQueryVariables>(TermsAndConditionDocument, options);
      }
export function useTermsAndConditionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TermsAndConditionQuery, TermsAndConditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TermsAndConditionQuery, TermsAndConditionQueryVariables>(TermsAndConditionDocument, options);
        }
export type TermsAndConditionQueryHookResult = ReturnType<typeof useTermsAndConditionQuery>;
export type TermsAndConditionLazyQueryHookResult = ReturnType<typeof useTermsAndConditionLazyQuery>;
export type TermsAndConditionQueryResult = Apollo.QueryResult<TermsAndConditionQuery, TermsAndConditionQueryVariables>;
export const CookiesTrackingPolicyDocument = gql`
    query CookiesTrackingPolicy($pagination: PaginationArg!) {
  cookiesTrackingPolicy {
    data {
      attributes {
        block(pagination: $pagination) {
          title
          description
        }
      }
    }
  }
}
    `;
export function useCookiesTrackingPolicyQuery(baseOptions: Apollo.QueryHookOptions<CookiesTrackingPolicyQuery, CookiesTrackingPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CookiesTrackingPolicyQuery, CookiesTrackingPolicyQueryVariables>(CookiesTrackingPolicyDocument, options);
      }
export function useCookiesTrackingPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CookiesTrackingPolicyQuery, CookiesTrackingPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CookiesTrackingPolicyQuery, CookiesTrackingPolicyQueryVariables>(CookiesTrackingPolicyDocument, options);
        }
export type CookiesTrackingPolicyQueryHookResult = ReturnType<typeof useCookiesTrackingPolicyQuery>;
export type CookiesTrackingPolicyLazyQueryHookResult = ReturnType<typeof useCookiesTrackingPolicyLazyQuery>;
export type CookiesTrackingPolicyQueryResult = Apollo.QueryResult<CookiesTrackingPolicyQuery, CookiesTrackingPolicyQueryVariables>;
export const GetAllSchoolStudentDocument = gql`
    query getAllSchoolStudent($id: ID!, $name: String) {
  school(id: $id) {
    data {
      attributes {
        students(filters: {name: {contains: $name}}) {
          data {
            id
            attributes {
              name
              email
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetAllSchoolStudentQuery(baseOptions: Apollo.QueryHookOptions<GetAllSchoolStudentQuery, GetAllSchoolStudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSchoolStudentQuery, GetAllSchoolStudentQueryVariables>(GetAllSchoolStudentDocument, options);
      }
export function useGetAllSchoolStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSchoolStudentQuery, GetAllSchoolStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSchoolStudentQuery, GetAllSchoolStudentQueryVariables>(GetAllSchoolStudentDocument, options);
        }
export type GetAllSchoolStudentQueryHookResult = ReturnType<typeof useGetAllSchoolStudentQuery>;
export type GetAllSchoolStudentLazyQueryHookResult = ReturnType<typeof useGetAllSchoolStudentLazyQuery>;
export type GetAllSchoolStudentQueryResult = Apollo.QueryResult<GetAllSchoolStudentQuery, GetAllSchoolStudentQueryVariables>;
export const GetAllSchoolTeachersDocument = gql`
    query getAllSchoolTeachers($filters: SubjectFiltersInput!, $name: String) {
  subjects(filters: $filters) {
    data {
      id
      attributes {
        name
        teachers(filters: {name: {contains: $name}}) {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetAllSchoolTeachersQuery(baseOptions: Apollo.QueryHookOptions<GetAllSchoolTeachersQuery, GetAllSchoolTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSchoolTeachersQuery, GetAllSchoolTeachersQueryVariables>(GetAllSchoolTeachersDocument, options);
      }
export function useGetAllSchoolTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSchoolTeachersQuery, GetAllSchoolTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSchoolTeachersQuery, GetAllSchoolTeachersQueryVariables>(GetAllSchoolTeachersDocument, options);
        }
export type GetAllSchoolTeachersQueryHookResult = ReturnType<typeof useGetAllSchoolTeachersQuery>;
export type GetAllSchoolTeachersLazyQueryHookResult = ReturnType<typeof useGetAllSchoolTeachersLazyQuery>;
export type GetAllSchoolTeachersQueryResult = Apollo.QueryResult<GetAllSchoolTeachersQuery, GetAllSchoolTeachersQueryVariables>;
export const GetChaptersDocument = gql`
    query getChapters($filters: ChapterFiltersInput, $pagination: PaginationArg) {
  chapters(filters: $filters, pagination: $pagination) {
    data {
      id
      attributes {
        name
        description
        topics {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    `;
export function useGetChaptersQuery(baseOptions?: Apollo.QueryHookOptions<GetChaptersQuery, GetChaptersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChaptersQuery, GetChaptersQueryVariables>(GetChaptersDocument, options);
      }
export function useGetChaptersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChaptersQuery, GetChaptersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChaptersQuery, GetChaptersQueryVariables>(GetChaptersDocument, options);
        }
export type GetChaptersQueryHookResult = ReturnType<typeof useGetChaptersQuery>;
export type GetChaptersLazyQueryHookResult = ReturnType<typeof useGetChaptersLazyQuery>;
export type GetChaptersQueryResult = Apollo.QueryResult<GetChaptersQuery, GetChaptersQueryVariables>;
export const GetChaptersListDocument = gql`
    query getChaptersList($filters: ChapterFiltersInput, $pagination: PaginationArg) {
  chapters(filters: $filters, pagination: $pagination) {
    data {
      id
      attributes {
        name
        description
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    `;
export function useGetChaptersListQuery(baseOptions?: Apollo.QueryHookOptions<GetChaptersListQuery, GetChaptersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChaptersListQuery, GetChaptersListQueryVariables>(GetChaptersListDocument, options);
      }
export function useGetChaptersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChaptersListQuery, GetChaptersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChaptersListQuery, GetChaptersListQueryVariables>(GetChaptersListDocument, options);
        }
export type GetChaptersListQueryHookResult = ReturnType<typeof useGetChaptersListQuery>;
export type GetChaptersListLazyQueryHookResult = ReturnType<typeof useGetChaptersListLazyQuery>;
export type GetChaptersListQueryResult = Apollo.QueryResult<GetChaptersListQuery, GetChaptersListQueryVariables>;
export const GetClassDocument = gql`
    query getClass($id: ID!) {
  class(id: $id) {
    data {
      id
      attributes {
        students {
          data {
            attributes {
              name
              email
            }
            id
          }
        }
        name
        subjects {
          data {
            id
            attributes {
              name
              teachers {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
        level {
          data {
            id
            attributes {
              name
              type
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetClassQuery(baseOptions: Apollo.QueryHookOptions<GetClassQuery, GetClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassQuery, GetClassQueryVariables>(GetClassDocument, options);
      }
export function useGetClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassQuery, GetClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassQuery, GetClassQueryVariables>(GetClassDocument, options);
        }
export type GetClassQueryHookResult = ReturnType<typeof useGetClassQuery>;
export type GetClassLazyQueryHookResult = ReturnType<typeof useGetClassLazyQuery>;
export type GetClassQueryResult = Apollo.QueryResult<GetClassQuery, GetClassQueryVariables>;
export const GetClassHomeWorksDocument = gql`
    query GetClassHomeWorks($classId: String!, $limit: Int, $start: Int, $week: String!) {
  getClassHomeWorks(classId: $classId, limit: $limit, start: $start, week: $week) {
    data
    pagination {
      page
      pageCount
      total
    }
  }
}
    `;
export function useGetClassHomeWorksQuery(baseOptions: Apollo.QueryHookOptions<GetClassHomeWorksQuery, GetClassHomeWorksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassHomeWorksQuery, GetClassHomeWorksQueryVariables>(GetClassHomeWorksDocument, options);
      }
export function useGetClassHomeWorksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassHomeWorksQuery, GetClassHomeWorksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassHomeWorksQuery, GetClassHomeWorksQueryVariables>(GetClassHomeWorksDocument, options);
        }
export type GetClassHomeWorksQueryHookResult = ReturnType<typeof useGetClassHomeWorksQuery>;
export type GetClassHomeWorksLazyQueryHookResult = ReturnType<typeof useGetClassHomeWorksLazyQuery>;
export type GetClassHomeWorksQueryResult = Apollo.QueryResult<GetClassHomeWorksQuery, GetClassHomeWorksQueryVariables>;
export const GetClassesDocument = gql`
    query getClasses($filters: ClassFiltersInput, $pagination: PaginationArg) {
  classes(filters: $filters, pagination: $pagination) {
    data {
      id
      attributes {
        name
        level {
          data {
            attributes {
              type
            }
          }
        }
        students {
          data {
            attributes {
              email
            }
          }
        }
        subjects {
          data {
            attributes {
              teachers {
                data {
                  attributes {
                    email
                  }
                }
              }
              name
            }
          }
        }
      }
    }
    meta {
      pagination {
        page
        pageSize
        pageCount
      }
    }
  }
}
    `;
export function useGetClassesQuery(baseOptions?: Apollo.QueryHookOptions<GetClassesQuery, GetClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassesQuery, GetClassesQueryVariables>(GetClassesDocument, options);
      }
export function useGetClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassesQuery, GetClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassesQuery, GetClassesQueryVariables>(GetClassesDocument, options);
        }
export type GetClassesQueryHookResult = ReturnType<typeof useGetClassesQuery>;
export type GetClassesLazyQueryHookResult = ReturnType<typeof useGetClassesLazyQuery>;
export type GetClassesQueryResult = Apollo.QueryResult<GetClassesQuery, GetClassesQueryVariables>;
export const GetQuizResponsesDocument = gql`
    query getQuizResponses($filter: QuizResponseFiltersInput) {
  quizResponses(filters: $filter) {
    data {
      attributes {
        response
        student {
          data {
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetQuizResponsesQuery(baseOptions?: Apollo.QueryHookOptions<GetQuizResponsesQuery, GetQuizResponsesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuizResponsesQuery, GetQuizResponsesQueryVariables>(GetQuizResponsesDocument, options);
      }
export function useGetQuizResponsesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuizResponsesQuery, GetQuizResponsesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuizResponsesQuery, GetQuizResponsesQueryVariables>(GetQuizResponsesDocument, options);
        }
export type GetQuizResponsesQueryHookResult = ReturnType<typeof useGetQuizResponsesQuery>;
export type GetQuizResponsesLazyQueryHookResult = ReturnType<typeof useGetQuizResponsesLazyQuery>;
export type GetQuizResponsesQueryResult = Apollo.QueryResult<GetQuizResponsesQuery, GetQuizResponsesQueryVariables>;
export const GetSchoolStudentsDocument = gql`
    query getSchoolStudents($level: String!, $limit: Int, $start: Int) {
  getSchoolStudents(level: $level, limit: $limit, start: $start) {
    data
    pagination {
      total
      page
      page
    }
  }
}
    `;
export function useGetSchoolStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetSchoolStudentsQuery, GetSchoolStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolStudentsQuery, GetSchoolStudentsQueryVariables>(GetSchoolStudentsDocument, options);
      }
export function useGetSchoolStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolStudentsQuery, GetSchoolStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolStudentsQuery, GetSchoolStudentsQueryVariables>(GetSchoolStudentsDocument, options);
        }
export type GetSchoolStudentsQueryHookResult = ReturnType<typeof useGetSchoolStudentsQuery>;
export type GetSchoolStudentsLazyQueryHookResult = ReturnType<typeof useGetSchoolStudentsLazyQuery>;
export type GetSchoolStudentsQueryResult = Apollo.QueryResult<GetSchoolStudentsQuery, GetSchoolStudentsQueryVariables>;
export const GetSchoolSubjectsDocument = gql`
    query getSchoolSubjects($schoolId: String!, $level: String!, $limit: Int, $start: Int, $isAssigned: Boolean) {
  getSchoolSubjects(
    schoolId: $schoolId
    isAssigned: $isAssigned
    level: $level
    limit: $limit
    start: $start
  ) {
    data {
      teachersCount
      id
      isAssigned
      classesCount
      name
    }
    pagination {
      page
      pageCount
      total
    }
  }
}
    `;
export function useGetSchoolSubjectsQuery(baseOptions: Apollo.QueryHookOptions<GetSchoolSubjectsQuery, GetSchoolSubjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolSubjectsQuery, GetSchoolSubjectsQueryVariables>(GetSchoolSubjectsDocument, options);
      }
export function useGetSchoolSubjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolSubjectsQuery, GetSchoolSubjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolSubjectsQuery, GetSchoolSubjectsQueryVariables>(GetSchoolSubjectsDocument, options);
        }
export type GetSchoolSubjectsQueryHookResult = ReturnType<typeof useGetSchoolSubjectsQuery>;
export type GetSchoolSubjectsLazyQueryHookResult = ReturnType<typeof useGetSchoolSubjectsLazyQuery>;
export type GetSchoolSubjectsQueryResult = Apollo.QueryResult<GetSchoolSubjectsQuery, GetSchoolSubjectsQueryVariables>;
export const GetSchoolTeachersDocument = gql`
    query getSchoolTeachers($level: String!, $limit: Int, $start: Int) {
  getSchoolTeachers(level: $level, limit: $limit, start: $start) {
    data
  }
}
    `;
export function useGetSchoolTeachersQuery(baseOptions: Apollo.QueryHookOptions<GetSchoolTeachersQuery, GetSchoolTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolTeachersQuery, GetSchoolTeachersQueryVariables>(GetSchoolTeachersDocument, options);
      }
export function useGetSchoolTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolTeachersQuery, GetSchoolTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolTeachersQuery, GetSchoolTeachersQueryVariables>(GetSchoolTeachersDocument, options);
        }
export type GetSchoolTeachersQueryHookResult = ReturnType<typeof useGetSchoolTeachersQuery>;
export type GetSchoolTeachersLazyQueryHookResult = ReturnType<typeof useGetSchoolTeachersLazyQuery>;
export type GetSchoolTeachersQueryResult = Apollo.QueryResult<GetSchoolTeachersQuery, GetSchoolTeachersQueryVariables>;
export const GetSchoolsDocument = gql`
    query getSchools($pagination: PaginationArg) {
  schools(pagination: $pagination) {
    data {
      id
      attributes {
        name
        address
        students {
          data {
            id
          }
        }
        teachers {
          data {
            id
          }
        }
        school_admin {
          data {
            attributes {
              email
            }
          }
        }
      }
    }
    meta {
      pagination {
        page
        pageCount
        total
      }
    }
  }
}
    `;
export function useGetSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<GetSchoolsQuery, GetSchoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(GetSchoolsDocument, options);
      }
export function useGetSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolsQuery, GetSchoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolsQuery, GetSchoolsQueryVariables>(GetSchoolsDocument, options);
        }
export type GetSchoolsQueryHookResult = ReturnType<typeof useGetSchoolsQuery>;
export type GetSchoolsLazyQueryHookResult = ReturnType<typeof useGetSchoolsLazyQuery>;
export type GetSchoolsQueryResult = Apollo.QueryResult<GetSchoolsQuery, GetSchoolsQueryVariables>;
export const GetStudentChaptersDetailsDocument = gql`
    query getStudentChaptersDetails($start: Int, $limit: Int, $unitId: String) {
  getStudentChaptersDetails(start: $start, limit: $limit, unitId: $unitId) {
    data
    pagination {
      page
      pageCount
      total
    }
  }
}
    `;
export function useGetStudentChaptersDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentChaptersDetailsQuery, GetStudentChaptersDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentChaptersDetailsQuery, GetStudentChaptersDetailsQueryVariables>(GetStudentChaptersDetailsDocument, options);
      }
export function useGetStudentChaptersDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentChaptersDetailsQuery, GetStudentChaptersDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentChaptersDetailsQuery, GetStudentChaptersDetailsQueryVariables>(GetStudentChaptersDetailsDocument, options);
        }
export type GetStudentChaptersDetailsQueryHookResult = ReturnType<typeof useGetStudentChaptersDetailsQuery>;
export type GetStudentChaptersDetailsLazyQueryHookResult = ReturnType<typeof useGetStudentChaptersDetailsLazyQuery>;
export type GetStudentChaptersDetailsQueryResult = Apollo.QueryResult<GetStudentChaptersDetailsQuery, GetStudentChaptersDetailsQueryVariables>;
export const GetStudentHomeworksDocument = gql`
    query getStudentHomeworks($limit: Int, $start: Int) {
  getStudentHomeworks(limit: $limit, start: $start) {
    data
    pagination {
      total
      pageCount
      page
    }
  }
}
    `;
export function useGetStudentHomeworksQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentHomeworksQuery, GetStudentHomeworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentHomeworksQuery, GetStudentHomeworksQueryVariables>(GetStudentHomeworksDocument, options);
      }
export function useGetStudentHomeworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentHomeworksQuery, GetStudentHomeworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentHomeworksQuery, GetStudentHomeworksQueryVariables>(GetStudentHomeworksDocument, options);
        }
export type GetStudentHomeworksQueryHookResult = ReturnType<typeof useGetStudentHomeworksQuery>;
export type GetStudentHomeworksLazyQueryHookResult = ReturnType<typeof useGetStudentHomeworksLazyQuery>;
export type GetStudentHomeworksQueryResult = Apollo.QueryResult<GetStudentHomeworksQuery, GetStudentHomeworksQueryVariables>;
export const GetStudentHomeWorkByTeacherDocument = gql`
    query getStudentHomeWorkByTeacher($homeworkIds: String!, $start: Int, $limit: Int) {
  getStudentHomeWorkByTeacher(
    homeworkIds: $homeworkIds
    start: $start
    limit: $limit
  ) {
    data
    pagination {
      page
      pageCount
      total
    }
  }
}
    `;
export function useGetStudentHomeWorkByTeacherQuery(baseOptions: Apollo.QueryHookOptions<GetStudentHomeWorkByTeacherQuery, GetStudentHomeWorkByTeacherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentHomeWorkByTeacherQuery, GetStudentHomeWorkByTeacherQueryVariables>(GetStudentHomeWorkByTeacherDocument, options);
      }
export function useGetStudentHomeWorkByTeacherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentHomeWorkByTeacherQuery, GetStudentHomeWorkByTeacherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentHomeWorkByTeacherQuery, GetStudentHomeWorkByTeacherQueryVariables>(GetStudentHomeWorkByTeacherDocument, options);
        }
export type GetStudentHomeWorkByTeacherQueryHookResult = ReturnType<typeof useGetStudentHomeWorkByTeacherQuery>;
export type GetStudentHomeWorkByTeacherLazyQueryHookResult = ReturnType<typeof useGetStudentHomeWorkByTeacherLazyQuery>;
export type GetStudentHomeWorkByTeacherQueryResult = Apollo.QueryResult<GetStudentHomeWorkByTeacherQuery, GetStudentHomeWorkByTeacherQueryVariables>;
export const GetStudentHomeworkDetailsDocument = gql`
    query getStudentHomeworkDetails($id: ID!) {
  homework(id: $id) {
    data {
      id
      attributes {
        name
        deadline
        createdAt
        completionRate
        status
      }
    }
  }
}
    `;
export function useGetStudentHomeworkDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetStudentHomeworkDetailsQuery, GetStudentHomeworkDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentHomeworkDetailsQuery, GetStudentHomeworkDetailsQueryVariables>(GetStudentHomeworkDetailsDocument, options);
      }
export function useGetStudentHomeworkDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentHomeworkDetailsQuery, GetStudentHomeworkDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentHomeworkDetailsQuery, GetStudentHomeworkDetailsQueryVariables>(GetStudentHomeworkDetailsDocument, options);
        }
export type GetStudentHomeworkDetailsQueryHookResult = ReturnType<typeof useGetStudentHomeworkDetailsQuery>;
export type GetStudentHomeworkDetailsLazyQueryHookResult = ReturnType<typeof useGetStudentHomeworkDetailsLazyQuery>;
export type GetStudentHomeworkDetailsQueryResult = Apollo.QueryResult<GetStudentHomeworkDetailsQuery, GetStudentHomeworkDetailsQueryVariables>;
export const GetStudentSubjectsDetailsDocument = gql`
    query getStudentSubjectsDetails($start: Int, $limit: Int, $levelId: String) {
  getStudentSubjectsDetails(start: $start, limit: $limit, levelId: $levelId) {
    data
    pagination {
      page
      pageCount
      total
    }
  }
}
    `;
export function useGetStudentSubjectsDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentSubjectsDetailsQuery, GetStudentSubjectsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentSubjectsDetailsQuery, GetStudentSubjectsDetailsQueryVariables>(GetStudentSubjectsDetailsDocument, options);
      }
export function useGetStudentSubjectsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentSubjectsDetailsQuery, GetStudentSubjectsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentSubjectsDetailsQuery, GetStudentSubjectsDetailsQueryVariables>(GetStudentSubjectsDetailsDocument, options);
        }
export type GetStudentSubjectsDetailsQueryHookResult = ReturnType<typeof useGetStudentSubjectsDetailsQuery>;
export type GetStudentSubjectsDetailsLazyQueryHookResult = ReturnType<typeof useGetStudentSubjectsDetailsLazyQuery>;
export type GetStudentSubjectsDetailsQueryResult = Apollo.QueryResult<GetStudentSubjectsDetailsQuery, GetStudentSubjectsDetailsQueryVariables>;
export const GetStudentTopicDocument = gql`
    query getStudentTopic($topicId: String!) {
  getStudentTopic(topicId: $topicId) {
    id
    name
    quiz
    revisionPoints
    videoTitle
    videoUrl
    quizResult
  }
}
    `;
export function useGetStudentTopicQuery(baseOptions: Apollo.QueryHookOptions<GetStudentTopicQuery, GetStudentTopicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentTopicQuery, GetStudentTopicQueryVariables>(GetStudentTopicDocument, options);
      }
export function useGetStudentTopicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentTopicQuery, GetStudentTopicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentTopicQuery, GetStudentTopicQueryVariables>(GetStudentTopicDocument, options);
        }
export type GetStudentTopicQueryHookResult = ReturnType<typeof useGetStudentTopicQuery>;
export type GetStudentTopicLazyQueryHookResult = ReturnType<typeof useGetStudentTopicLazyQuery>;
export type GetStudentTopicQueryResult = Apollo.QueryResult<GetStudentTopicQuery, GetStudentTopicQueryVariables>;
export const GetStudentTopicsDetailsDocument = gql`
    query getStudentTopicsDetails($start: Int, $limit: Int, $chapterId: String) {
  getStudentTopicsDetails(start: $start, limit: $limit, chapterId: $chapterId) {
    data
    pagination {
      page
      pageCount
      total
    }
  }
}
    `;
export function useGetStudentTopicsDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentTopicsDetailsQuery, GetStudentTopicsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentTopicsDetailsQuery, GetStudentTopicsDetailsQueryVariables>(GetStudentTopicsDetailsDocument, options);
      }
export function useGetStudentTopicsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentTopicsDetailsQuery, GetStudentTopicsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentTopicsDetailsQuery, GetStudentTopicsDetailsQueryVariables>(GetStudentTopicsDetailsDocument, options);
        }
export type GetStudentTopicsDetailsQueryHookResult = ReturnType<typeof useGetStudentTopicsDetailsQuery>;
export type GetStudentTopicsDetailsLazyQueryHookResult = ReturnType<typeof useGetStudentTopicsDetailsLazyQuery>;
export type GetStudentTopicsDetailsQueryResult = Apollo.QueryResult<GetStudentTopicsDetailsQuery, GetStudentTopicsDetailsQueryVariables>;
export const GetStudentUnitsDetailsDocument = gql`
    query getStudentUnitsDetails($start: Int, $limit: Int, $subjectId: String) {
  getStudentUnitsDetails(start: $start, limit: $limit, subjectId: $subjectId) {
    data
    pagination {
      page
      pageCount
      total
    }
  }
}
    `;
export function useGetStudentUnitsDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentUnitsDetailsQuery, GetStudentUnitsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentUnitsDetailsQuery, GetStudentUnitsDetailsQueryVariables>(GetStudentUnitsDetailsDocument, options);
      }
export function useGetStudentUnitsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentUnitsDetailsQuery, GetStudentUnitsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentUnitsDetailsQuery, GetStudentUnitsDetailsQueryVariables>(GetStudentUnitsDetailsDocument, options);
        }
export type GetStudentUnitsDetailsQueryHookResult = ReturnType<typeof useGetStudentUnitsDetailsQuery>;
export type GetStudentUnitsDetailsLazyQueryHookResult = ReturnType<typeof useGetStudentUnitsDetailsLazyQuery>;
export type GetStudentUnitsDetailsQueryResult = Apollo.QueryResult<GetStudentUnitsDetailsQuery, GetStudentUnitsDetailsQueryVariables>;
export const GetSubjectHomeWorksDocument = gql`
    query getSubjectHomeWorks($filters: HomeworkFiltersInput, $pagination: PaginationArg) {
  homeworks(filters: $filters, pagination: $pagination) {
    data {
      attributes {
        name
        deadline
        status
        completionRate
        createdAt
      }
      id
    }
    meta {
      pagination {
        total
        pageCount
      }
    }
  }
}
    `;
export function useGetSubjectHomeWorksQuery(baseOptions?: Apollo.QueryHookOptions<GetSubjectHomeWorksQuery, GetSubjectHomeWorksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubjectHomeWorksQuery, GetSubjectHomeWorksQueryVariables>(GetSubjectHomeWorksDocument, options);
      }
export function useGetSubjectHomeWorksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubjectHomeWorksQuery, GetSubjectHomeWorksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubjectHomeWorksQuery, GetSubjectHomeWorksQueryVariables>(GetSubjectHomeWorksDocument, options);
        }
export type GetSubjectHomeWorksQueryHookResult = ReturnType<typeof useGetSubjectHomeWorksQuery>;
export type GetSubjectHomeWorksLazyQueryHookResult = ReturnType<typeof useGetSubjectHomeWorksLazyQuery>;
export type GetSubjectHomeWorksQueryResult = Apollo.QueryResult<GetSubjectHomeWorksQuery, GetSubjectHomeWorksQueryVariables>;
export const GetSubjectsDocument = gql`
    query getSubjects($filters: SubjectFiltersInput!) {
  subjects(filters: $filters) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetSubjectsQuery(baseOptions: Apollo.QueryHookOptions<GetSubjectsQuery, GetSubjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubjectsQuery, GetSubjectsQueryVariables>(GetSubjectsDocument, options);
      }
export function useGetSubjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubjectsQuery, GetSubjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubjectsQuery, GetSubjectsQueryVariables>(GetSubjectsDocument, options);
        }
export type GetSubjectsQueryHookResult = ReturnType<typeof useGetSubjectsQuery>;
export type GetSubjectsLazyQueryHookResult = ReturnType<typeof useGetSubjectsLazyQuery>;
export type GetSubjectsQueryResult = Apollo.QueryResult<GetSubjectsQuery, GetSubjectsQueryVariables>;
export const GetSubjectsDetailsDocument = gql`
    query getSubjectsDetails($start: Int, $limit: Int, $levelId: String) {
  getSubjectsDetails(start: $start, limit: $limit, levelId: $levelId) {
    data {
      id
      chaptersCount
      unitsCount
      topicsCount
      name
      description
    }
    pagination {
      page
      pageCount
      total
    }
  }
}
    `;
export function useGetSubjectsDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubjectsDetailsQuery, GetSubjectsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubjectsDetailsQuery, GetSubjectsDetailsQueryVariables>(GetSubjectsDetailsDocument, options);
      }
export function useGetSubjectsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubjectsDetailsQuery, GetSubjectsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubjectsDetailsQuery, GetSubjectsDetailsQueryVariables>(GetSubjectsDetailsDocument, options);
        }
export type GetSubjectsDetailsQueryHookResult = ReturnType<typeof useGetSubjectsDetailsQuery>;
export type GetSubjectsDetailsLazyQueryHookResult = ReturnType<typeof useGetSubjectsDetailsLazyQuery>;
export type GetSubjectsDetailsQueryResult = Apollo.QueryResult<GetSubjectsDetailsQuery, GetSubjectsDetailsQueryVariables>;
export const GetTeacherHomeworksDocument = gql`
    query getTeacherHomeworks($level: String!, $limit: Int!, $start: Int!) {
  getTeacherHomeworks(level: $level, limit: $limit, start: $start) {
    data
    pagination {
      total
      pageCount
      page
    }
  }
}
    `;
export function useGetTeacherHomeworksQuery(baseOptions: Apollo.QueryHookOptions<GetTeacherHomeworksQuery, GetTeacherHomeworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeacherHomeworksQuery, GetTeacherHomeworksQueryVariables>(GetTeacherHomeworksDocument, options);
      }
export function useGetTeacherHomeworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeacherHomeworksQuery, GetTeacherHomeworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeacherHomeworksQuery, GetTeacherHomeworksQueryVariables>(GetTeacherHomeworksDocument, options);
        }
export type GetTeacherHomeworksQueryHookResult = ReturnType<typeof useGetTeacherHomeworksQuery>;
export type GetTeacherHomeworksLazyQueryHookResult = ReturnType<typeof useGetTeacherHomeworksLazyQuery>;
export type GetTeacherHomeworksQueryResult = Apollo.QueryResult<GetTeacherHomeworksQuery, GetTeacherHomeworksQueryVariables>;
export const GetTeachersDocument = gql`
    query getTeachers($id: ID!, $name: String) {
  school(id: $id) {
    data {
      attributes {
        teachers(filters: {name: {contains: $name}}) {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetTeachersQuery(baseOptions: Apollo.QueryHookOptions<GetTeachersQuery, GetTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeachersQuery, GetTeachersQueryVariables>(GetTeachersDocument, options);
      }
export function useGetTeachersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeachersQuery, GetTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeachersQuery, GetTeachersQueryVariables>(GetTeachersDocument, options);
        }
export type GetTeachersQueryHookResult = ReturnType<typeof useGetTeachersQuery>;
export type GetTeachersLazyQueryHookResult = ReturnType<typeof useGetTeachersLazyQuery>;
export type GetTeachersQueryResult = Apollo.QueryResult<GetTeachersQuery, GetTeachersQueryVariables>;
export const GetTopicDocument = gql`
    query getTopic($id: ID!) {
  topic(id: $id) {
    data {
      id
      attributes {
        name
        quiz {
          data {
            attributes {
              quiz
            }
          }
        }
        videoTitle
        videoUrl
        revisionPoints
      }
    }
  }
}
    `;
export function useGetTopicQuery(baseOptions: Apollo.QueryHookOptions<GetTopicQuery, GetTopicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicQuery, GetTopicQueryVariables>(GetTopicDocument, options);
      }
export function useGetTopicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicQuery, GetTopicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicQuery, GetTopicQueryVariables>(GetTopicDocument, options);
        }
export type GetTopicQueryHookResult = ReturnType<typeof useGetTopicQuery>;
export type GetTopicLazyQueryHookResult = ReturnType<typeof useGetTopicLazyQuery>;
export type GetTopicQueryResult = Apollo.QueryResult<GetTopicQuery, GetTopicQueryVariables>;
export const GetTopicsDocument = gql`
    query getTopics($filter: TopicFiltersInput, $pagination: PaginationArg) {
  topics(filters: $filter, pagination: $pagination) {
    data {
      id
      attributes {
        name
        quiz {
          data {
            attributes {
              quiz
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    `;
export function useGetTopicsQuery(baseOptions?: Apollo.QueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicsQuery, GetTopicsQueryVariables>(GetTopicsDocument, options);
      }
export function useGetTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicsQuery, GetTopicsQueryVariables>(GetTopicsDocument, options);
        }
export type GetTopicsQueryHookResult = ReturnType<typeof useGetTopicsQuery>;
export type GetTopicsLazyQueryHookResult = ReturnType<typeof useGetTopicsLazyQuery>;
export type GetTopicsQueryResult = Apollo.QueryResult<GetTopicsQuery, GetTopicsQueryVariables>;
export const GetTopicsListDocument = gql`
    query getTopicsList($filters: TopicFiltersInput!) {
  topics(filters: $filters) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetTopicsListQuery(baseOptions: Apollo.QueryHookOptions<GetTopicsListQuery, GetTopicsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicsListQuery, GetTopicsListQueryVariables>(GetTopicsListDocument, options);
      }
export function useGetTopicsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicsListQuery, GetTopicsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicsListQuery, GetTopicsListQueryVariables>(GetTopicsListDocument, options);
        }
export type GetTopicsListQueryHookResult = ReturnType<typeof useGetTopicsListQuery>;
export type GetTopicsListLazyQueryHookResult = ReturnType<typeof useGetTopicsListLazyQuery>;
export type GetTopicsListQueryResult = Apollo.QueryResult<GetTopicsListQuery, GetTopicsListQueryVariables>;
export const GetUnitsDocument = gql`
    query getUnits($filters: UnitFiltersInput) {
  units(filters: $filters) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetUnitsQuery(baseOptions?: Apollo.QueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
      }
export function useGetUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
        }
export type GetUnitsQueryHookResult = ReturnType<typeof useGetUnitsQuery>;
export type GetUnitsLazyQueryHookResult = ReturnType<typeof useGetUnitsLazyQuery>;
export type GetUnitsQueryResult = Apollo.QueryResult<GetUnitsQuery, GetUnitsQueryVariables>;
export const GetUnitsDetailsDocument = gql`
    query getUnitsDetails($subjectId: String!, $start: Int, $limit: Int) {
  getUnitsDetails(subjectId: $subjectId, start: $start, limit: $limit) {
    data {
      id
      description
      name
      chaptersCount
      topicsCount
      subjectId
    }
    pagination {
      page
      pageCount
      total
    }
  }
}
    `;
export function useGetUnitsDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUnitsDetailsQuery, GetUnitsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitsDetailsQuery, GetUnitsDetailsQueryVariables>(GetUnitsDetailsDocument, options);
      }
export function useGetUnitsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsDetailsQuery, GetUnitsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitsDetailsQuery, GetUnitsDetailsQueryVariables>(GetUnitsDetailsDocument, options);
        }
export type GetUnitsDetailsQueryHookResult = ReturnType<typeof useGetUnitsDetailsQuery>;
export type GetUnitsDetailsLazyQueryHookResult = ReturnType<typeof useGetUnitsDetailsLazyQuery>;
export type GetUnitsDetailsQueryResult = Apollo.QueryResult<GetUnitsDetailsQuery, GetUnitsDetailsQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const PrivacyPolicyDocument = gql`
    query PrivacyPolicy($pagination: PaginationArg!) {
  privacyPolicy {
    data {
      attributes {
        block(pagination: $pagination) {
          title
          description
        }
      }
    }
  }
}
    `;
export function usePrivacyPolicyQuery(baseOptions: Apollo.QueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
      }
export function usePrivacyPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>(PrivacyPolicyDocument, options);
        }
export type PrivacyPolicyQueryHookResult = ReturnType<typeof usePrivacyPolicyQuery>;
export type PrivacyPolicyLazyQueryHookResult = ReturnType<typeof usePrivacyPolicyLazyQuery>;
export type PrivacyPolicyQueryResult = Apollo.QueryResult<PrivacyPolicyQuery, PrivacyPolicyQueryVariables>;