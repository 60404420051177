import { useState, type FC } from 'react';
import { Layout as BaseLayout, Divider, Flex, Menu, MenuProps} from "antd"

import { ReactComponent as LogoBig } from "../../assets/icons/LogoBig.svg";
import { ReactComponent as LogoSmall } from "../../assets/icons/LogoSmall.svg";
import  {ReactComponent as SchoolIcon } from '../../assets/icons/School.svg';
import  {ReactComponent as SubjectIcon } from '../../assets/icons/Subject.svg';
// import  {ReactComponent as UsersIcon } from '../../assets/icons/Users.svg';
import  {ReactComponent as GearsIcon } from '../../assets/icons/Gears.svg';
import { NavLink } from 'react-router-dom';
import { useAuth } from "../app/AuthProvider"


type MenuItem = Required<MenuProps>['items'][number];

const LeftMenu: FC = () => {
  const {logout, user} = useAuth()
    const {Sider } = BaseLayout;

    const [collapsed, setCollapsed] = useState(false);

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
      ): MenuItem {
        return {
          key,
          icon,
          children,
          label,
          type,
        } as MenuItem;
      }
const adminMenu =[
  getItem('', 'grp1', null, [
    getItem(<NavLink to={"subjects"}>Subjects</NavLink>, '2', <SubjectIcon/>),
    getItem(<NavLink to={"schools"}>Schools</NavLink>, '3', <SchoolIcon />),
    // getItem(<NavLink to={"users"}>Users</NavLink>, '4', <UsersIcon />),
  ], 'group'),

  getItem('', 'grp2', null, [
    getItem(<NavLink to={"settings"}>Settings</NavLink>, '6', <GearsIcon />),
  ], 'group'),
];
const schoolMenu=[
  getItem('', 'grp1', null, [
    getItem(<NavLink to={"subjects"}>Subjects</NavLink>, '2', <SubjectIcon/>),
    getItem(<NavLink to={"classes"}>Classes</NavLink>, '3', <SchoolIcon />),
    // getItem(<NavLink to={"users"}>Users</NavLink>, '4', <UsersIcon />),
  ], 'group'),

  getItem('', 'grp2', null, [
    getItem(<NavLink to={"settings"}>Settings</NavLink>, '6', <GearsIcon />),
  ], 'group'),
]
  const studentMenu=[
    getItem('', 'grp1', null, [
      getItem(<NavLink to={"subjects"}>Subjects</NavLink>, '2', <SubjectIcon/>),
      getItem(<NavLink to={"homework"}>Homework</NavLink>, '3', <SchoolIcon />),
    ], 'group'),

    getItem('', 'grp2', null, [
      getItem(<NavLink to={"settings"}>Settings</NavLink>, '6', <GearsIcon />),
    ], 'group'),
  ]
  const teacherMenu=[
    getItem('', 'grp1', null, [
      getItem(<NavLink to={"subjects"}>Subjects</NavLink>, '2', <SubjectIcon/>),
      getItem(<NavLink to={"homeworksetter"}>HM setter</NavLink>, '3', <SchoolIcon />),
      getItem(<NavLink to={"homework"}>Homework</NavLink>, '4', <SchoolIcon />),
    ], 'group'),

    getItem('', 'grp2', null, [
      getItem(<NavLink to={"settings"}>Settings</NavLink>, '6', <GearsIcon />),
    ], 'group'),
  ]
    let items
  if(user?.role=== "Admin"){
    items = adminMenu
  }
  if(user?.role=== "School"){
    items = schoolMenu
  }
  if(user?.role=== "Student"){
    items = studentMenu
  }
  if(user?.role=== "Teacher"){
    items = teacherMenu
  }


    return (
        <Sider width={"256px"} collapsed={collapsed}>
            <button className={"sider-trigger"} onClick={()=>setCollapsed(prev=>!prev)}></button>
            <Flex className={"logo-black"}>
                {collapsed
                    ? <LogoSmall onClick={() => window.location.assign("/subjects")}/>
                    : <LogoBig onClick={() => window.location.assign("/subjects")}/>
                }
                <Divider className={"logo-driver"}/>
            </Flex>
            <nav>
                <Menu className={"sider-menu"} items={items} mode={"inline"}/>
            </nav>
        </Sider>
    );
}

export default LeftMenu;
