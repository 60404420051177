import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ConfigProvider } from "antd";
import React, { createContext, useContext, FC, memo, PropsWithChildren } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { version } from "../../../package.json";
import introspection from "../../graphql";

import theme from "../../themes";
import { getToken, removeToken } from "../../helpers/authHelper"
import { AuthProvider, useAuth } from "./AuthProvider";
import { adminRoutes, guestRoutes, schoolRoutes, studentRoutes, teacherRoutes } from "../../pages"
import { Roles } from "../../enums/role"

type User = {
  id: string;
  name: string;
  email: string;
  // Additional user fields as needed
};




type ContextProps = {
  app: { version: string };
};

const app: ContextProps["app"] = { version };

const Context = createContext({ app });

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  return <Context.Provider value={{ ...props }}>{children}</Context.Provider>;
};

const useApp: () => ContextProps = () => useContext(Context);

const httpLink = createHttpLink({
  uri:import.meta.env.WEBSITE_API_URL ?  `${import.meta.env.WEBSITE_API_URL}/graphql` : '/graphql',
  credentials: "same-origin",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getToken();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    removeToken()
  }
});

const client = new ApolloClient({
  link: from([errorLink, authMiddleware, httpLink]),
  connectToDevTools: import.meta.env.DEV,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
});
const Router = () => {
  const { user } = useAuth()
  let routes
  if (!user) {
    routes = guestRoutes
  }
  if(user?.role === Roles.admin){
    routes = adminRoutes
  }
  if(user?.role === Roles.school){
    routes = schoolRoutes;
  }
  if(user?.role === Roles.student){
    routes = studentRoutes;
  }
  if(user?.role === Roles.teacher){
    routes = teacherRoutes;
  }
  const router = createBrowserRouter(routes!);
  return <RouterProvider router={router} />
}

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <AuthProvider>
      <ContextProvider app={app}>
        <ConfigProvider theme={theme}>
        <Router/>
        </ConfigProvider>
      </ContextProvider>
    </AuthProvider>
  </ApolloProvider>
));

export { useApp };
export default App;
