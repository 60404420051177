import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/components/app/AuthProvider';
import React, { ReactNode } from 'react';
interface RequireAuthProps {
  children: ReactNode;
}

const RequirePermission: React.FC<RequireAuthProps> = ({ children }) => {
  const { isAuthenticated } = useAuth()
  return isAuthenticated() ? <React.Fragment>{children}</React.Fragment> : <Navigate to={"/sign-in"} replace /> ;
};

export default RequirePermission;
